import React from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import {HiOutlineMail} from 'react-icons/hi';



export default function Footer() {
    return(
        <div className ="bg-gray-800 py-10 text-white flex flex-col items-center">
            <div className ="flex gap-5">
                <a href="https://github.com/aduratovic"
                className="flex justify-between items-center w-full text-white"
                target="_blank"
                rel="noreferrer"
                >
                    <FaGithub size={30} />
                </a>
                <a href ="https://www.linkedin.com/in/ajdin-d/"
                className ="flex justify-between items-center w-full text-white"
                target="_blank"
                rel="noreferrer"
                >
                    <FaLinkedin size={30} />
                </a>

                
    <a href = 'mailto:aduratovic22@gmail.com'
    className='flex justify-between items-center w-full text-white'>
                 <HiOutlineMail size={30} /> 
    </a>



            </div>

        </div>
    )
}


