import React from 'react'

const About = () => {
  return (
    <div name ="about" className = "w-full h-screen bg-gradient-to-b from-gray-800 to-black text-white ">
        <div className = "max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full ">
           <div className = "pb-8 mt-20">
            <p className = "text-4xl font-bold inline border-b-4 border-gray-500 ">
                About
            </p>
           </div>
            <p className = "text-xl ">
            As a highly motivated full stack software developer, I approach every task with eagerness and a willingness to learn. With several years of experience in software development, I have learned the importance of taking away valuable insights from each project, especially the mistakes. I recently completed a rigorous Software Engineering bootcamp, where I gained valuable experience working on a range of applications.

            During the bootcamp, I had the opportunity to work closely with a Senior Software Engineer and receive invaluable advice and guidance that has further enhanced my skill set. I was also tested regularly on an array of subjects, which allowed me to expand my knowledge and solidify my understanding of core programming concepts.

            Since completing the bootcamp, I have continued to pursue opportunities to expand my knowledge and grow as a software engineer. I'm passionate about leveraging my skills and expertise to deliver high-quality applications that meet the needs of end-users. I'm confident in my ability to approach every project with a detail-oriented mindset and a commitment to delivering exceptional results.
            </p>

            
        </div>

    </div>
  )
};

export default About;