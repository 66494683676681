import React from 'react';
import html from "../assets/html.png";
import node from "../assets/node.png";
import tailwind from "../assets/tailwind.png";
import mysql from "../assets/mysql.png";
import javascript from "../assets/javascript.png";
import css from "../assets/css.png";
import react from "../assets/react.png";
import mongodb from "../assets/mongodb.png";
import python from "../assets/python.png";


const Experience = () => {

    const techs = [
        {
            id:1,
            src: html,
            title: 'HTML',
            style: 'shadow-orange-600'
        },
        {
            id:2,
            src: css,
            title: 'CSS',
            style: 'shadow-blue-500'
        },
        {
            id:3,
            src: javascript,
            title: 'JavaScript',
            style: 'shadow-yellow-500'
        },
        {
            id:4,
            src: react,
            title: 'React',
            style: 'shadow-cyan-400'
        },
        {
            id:5,
            src: mongodb,
            title: 'MongoDB',
            style: 'shadow-green-500'
        },
        {
            id:6,
            src: mysql,
            title: 'mySQL',
            style: 'shadow-orange-400'
        },
        {
            id:7,
            src: node,
            title: 'NODE JS',
            style: 'shadow-lime-300'
        },
        {
            id:8,
            src: tailwind,
            title: 'Tailwind',
            style: 'shadow-cyan-600'
        },
        {
            id:9,
            src: python,
            title: 'Python',
            style: 'shadow-yellow-300'
        },
    ]

  return (
    <div name='experience' className="bg-gradient-to-b from-gray-800 to-black w-full h-screen pt-20">
        <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
            <div>
                <p className="text-4xl font-bold inline border-b-4 border-gray-500 p-2">Experience</p>

                
            </div>

            <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-9 text-center py-8 px-12 sm:px-0 pt-16">

            {techs.map(({ id, src, title, style}) =>(
  <div key={id}className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}>
  
  <img src={src} alt="" className="w-20 mx-auto"/>
      <p className="mt-4">{title}</p>
</div>
            ))}
            


                
                
            </div>
        </div>

    </div>
  )
};

export default Experience