import React from 'react';
import fitlog from '../assets/portfolio/fitlog.png';
import rainy from '../assets/portfolio/rainy.png';
import moviebuff from '../assets/portfolio/moviebuff.png';
import chatt from '../assets/portfolio/chatt.png';
import loggo from '../assets/portfolio/loggo.png';
import movieBuffalo from '../assets/portfolio/movieBuffalo.png';
import rainyDays from '../assets/portfolio/rainyDays.png';
import chatter from '../assets/portfolio/chatter.png';


const Portfolio = () => {

    const portfolios= [
        {
            id:1,
            src: fitlog,
            src2: loggo,
            demourl: 'https://fit-log8.netlify.app/login',
            codeurl: 'https://github.com/aduratovic/fitness'
        },
        {
            id:2,
            src: chatt,
            src2: chatter,
            demourl: 'https://chatter-5474e.firebaseapp.com/',
            codeurl: 'https://github.com/aduratovic/Chatter'
        },
        {
            id:3,
            src: rainy,
            src2: rainyDays,
            demourl: 'https://rainy-days.netlify.app/',
            codeurl: 'https://github.com/aduratovic/Rainy-Days'
        },
        {
            id:4,
            src: moviebuff,
            src2: movieBuffalo,
            demourl: 'https://moviebuffalo.netlify.app/',
            codeurl: 'https://github.com/JohnsHarrison/GroupAPI'
        },
       
    ];


        const handleMouseEnter = (id)=> {
            const r = portfolios.filter(el => {
                return el.id == id;
            })
            const img = document.querySelector(`#img_${id}`)
            img.src = r[0].src2
        }

        const handleMouseLeave = (id) => { const r = portfolios.filter(el => {
            return el.id == id;
        })
        const img = document.querySelector(`#img_${id}`)
        img.src = r[0].src
    }

  return (
    <div name="portfolio" className="bg-gradient-to-b from-black to-gray-800 w-full text-white md:h-screen pt-20">
        
        <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full ">

            <div className="pb-8">
                <p className="text-4xl font-bold inline border-b-4 border-gray-500">Portfolio</p>
                
                
            </div>

           

            <div className="grid sm:grid-cols-2 gap-8 px-12 lg:px-[130px] sm:px-0 ">

             {
                 portfolios.map(({id, src, codeurl, demourl }) =>(
                        <div key={id} className="shadow-md shadow-gray-600 rounded-lg ">
           
                         <img id={`img_${id}`}
                         src={src}
                         width={350}
                         style={{ height: 'fit-content'}} 
                         alt="" 
                         onMouseEnter = {() => {handleMouseEnter(id)}}
                         onMouseLeave = {() => {handleMouseLeave(id)}}
                         className="rounded-md duration-200 hover:scale-105 " />
                                 <div className ="flex items-center justify-center ">
                                    <a href={demourl} target="_blank" rel="noreferrer">
                                  <button className="w-1/2  px-6 py-3 m-4 duration-200 hover:scale-105">Demo</button>
                                    </a>
                                    <a href={codeurl} target="_blank" rel="noreferrer">
                                  <button className="w-1/2  px-6 py-3 m-4 duration-200 hover:scale-105">Code</button>
                                  </a>
                                </div>
                        </div>
                 ))
             }

                
            </div>
        </div>
    </div>
  );
}

export default Portfolio;